import "../components/base.css";
import Layout from "../components/layout";
import React from "react";

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div> 
          <br></br>
          <h1>&gt; STAR CITIZEN</h1>
          <br></br>
          <h2>GET 5,000 STARTING UEC FREE /</h2>
          <h3>Referral code: STAR-TMWW-C2TV will give you 5,000 starting UEC</h3>
          <br></br>
          <h2>JAIL BREAK /</h2>
          <h3>Find your way out using this <a rel="noreferrer" target="_blank" href="https://external-preview.redd.it/PBr_3NT1lVqvUfABj3GIIw-S79q_iXEsSKbAvH1atKg.jpg?auto=webp&s=de9dc12d5a27a18fa6389ce403de5af2ddd5a331">map</a></h3>
          <br></br>
        </div>
      </Layout>
    );
  }
}